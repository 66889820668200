export default {
  "filters.agrupacion": "Information Group",
  "filters.calculan": "Calculado por",
  "filters.confidencialidad": "Confidentiality",
  "filters.pais": "Country",
  "filters.criticidad": "Criticality",
  "filters.data_officer": "Data Officer",
  "filters.data_owner": "Data Owner",
  "filters.data_steward": "Data Steward",
  "filters.dominio_informacion": "Management Information",
  "filters.fase1": "DWH Phase I",
  "filters.functional_owner": "Functional Owner",
  "filters.idioma": "Language",
  "filters.naturaleza": "Nature",
  "filters.sensible": "Sensitive",
  "filters.sujeto_gdpr": "GDPR Compliance",
  "filters.temporalidad": "Temporal aggregation",
  "filters.tipo": "Concept Type",
  "filters._confidential": "Confidential",
  "filters.history": "History",
  "filters.periodicity": "Periodicity",
  "filters.scope": "Scope",
  "filters.owner": "Data Owner",
  "filters.dq_periodicity": "Periodicity",
  "filters.dq_principle": "Data Quality principle",
  "filters.class.raw": "Class",
  "filters.class.raw.field": "Field",
  "filters.with_content.raw": "Extra Info",
  "filters.businesstermtype": "Business Term Type",
  "filters.criticalitylevel": "Criticality Level",
  "filters.datasteward": "Data Steward",
  "filters.datasponsor": "Data Sponsor",
  "filters.language": "Language",
  "filters.reportlevel": "Report Level",

  //Notes personalization
  "structures.uploadStructures.failed.note.only_draft_are_editable":
    "The extra info in row {row} need to be on draft status",
  "tabs.dd.notes": "Extra Info",
  "structure.notes.create": "Create Extra Info",
  "structure.notes.edit": "Edit extra info",
  "structure.notes.empty": "there is no extra info",
  "structure.notes.actions.deleted.confirmation.header": "Delete extra info",
  "structure.notes.actions.deleted.confirmation.content":
    "Extra info will be deleted. Are you sure?",
  "structure.notes.actions.deprecated.confirmation.header":
    "Deprecate extra info",
  "structure.notes.actions.deprecated.confirmation.content":
    "Extra info will be deprecated. Are you sure?",

  //Filters DD
  "filters.availability": "Availability",
  "filters.confidentiality": "Confidentiality",
  "filters.kpi": "Key Performance Indicator (KPI)",
  "filters.layer": "Layer",
  "filters.gdpr": "GDPR",
  "filters.updatefrequency": "Update Frequency",
  "filters.updatetype": "Update Type",
  "filters.business_area": "Business Area",
  "filters.democratized": "Democratized",
  "filters.historicaldepth": "Historical Depth",
  "filters.initiative": "Initiative",
  "filters.obsolescencia_analitica": "Analytic Obsolescence",
  "filters.retention": "Retention",
  "filters.rowconfidentiality": "Row Confidenciality",

  //
  "login.form.actions.oidc.login": "Login Moeve Chemicals",
  "login.description": "Data Governance",

  "concepts.actions.upload.confirmation.header": "Upload concepts file",
  "domain.members.delete": "Delete member",
  "structure.mark_as_confidential": "Mark as confidential",
  "rule.type.min_date": "Minimum date",

  "navigation.glossary.concepts": "Glossary",
  "navigation.dictionary.structures": "Catalog",
  "navigation.ingest.ingests": "Processes",
  "navigation.quality.rules": "Quality",
  "navigation.quality.dashboard": "Quality Dashboard",

  "structure.lopd": "GDPR Compliance",
  "conceptRelations.relationType": "Relation's type",

  //Concepts

  "concepts.props.type_label": "Type",

  //Structure types

  "structure.type.Instance.text": "Instance",
  "structure.type.Instance.icon": "server",

  "structure.type.Column.text": "Column",
  "structure.type.Column.icon": "columns",

  "structure.type.Table.text": "Table",
  "structure.type.Table.icon": "table",

  "structure.type.table.text": "Table",
  "structure.type.table.icon": "table",

  "structure.type.Database.text": "Database",
  "structure.type.Database.icon": "database",

  "structure.type.database.text": "Database",
  "structure.type.database.icon": "database",

  "structure.type.Schema.text": "Schema",
  "structure.type.Schema.icon": "database",

  "structure.type.Report.text": "Report",
  "structure.type.Report.icon": "chart bar",
  "structure.type.Report.lineage.icon": "chart-bar",

  "structure.type.Carpeta.text": "Folder",
  "structure.type.Carpeta.icon": "folder",

  "structure.type.Folder.text": "Folder",
  "structure.type.Folder.icon": "folder",

  "structure.type.Documento.text": "Document",
  "structure.type.Documento.icon": "file outline",
  "structure.type.Documento.lineage.icon": "file",

  "structure.type.Informe.text": "Dashboard",
  "structure.type.Informe.icon": "chart bar",
  "structure.type.Informe.lineage.icon": "chart-bar",

  "structure.type.Field.text": "Field",
  "structure.type.Field.icon": "columns",

  "structure.type.BASE TABLE.text": "Base table",
  "structure.type.BASE TABLE.icon": "table",

  "structure.type.DssTypeDocumentDefinition.text": "DssTypeDocumentDefinition",
  "structure.type.DssTypeDocumentDefinition.icon": "file outline",
  "structure.type.DssTypeDocumentDefinition.lineage.icon": "file",

  "structure.type.DssTypeReportDefinition.text": "DssTypeReportDefinition",
  "structure.type.DssTypeReportDefinition.icon": "chart bar",
  "structure.type.DssTypeReportDefinition.lineage.icon": "chart-bar",

  "structure.type.DssTypeFolder.text": "DssTypeFolder",
  "structure.type.DssTypeFolder.icon": "folder",

  "structure.type.Calculated Table.text": "Calculated Table",
  "structure.type.Calculated Table.icon": "table",

  "structure.type.USER_TABLE.text": "User table",
  "structure.type.USER_TABLE.icon": "table",

  "structure.type.VIEW.text": "View",
  "structure.type.VIEW.icon": "table",

  "structure.type.DIRECTORY.text": "Directory",
  "structure.type.DIRECTORY.icon": "folder",

  "structure.type.FILE.text": "File",
  "structure.type.FILE.icon": "file outline",
  "structure.type.FILE.lineage.icon": "file",

  "structure.type.EXTERNAL_TABLE.text": "External table",
  "structure.type.EXTERNAL_TABLE.icon": "table",

  // MYSQL
  "structure.type.varchar.text": "Column",
  "structure.type.varchar.icon": "font",

  "structure.type.datetime.text": "Column",
  "structure.type.datetime.icon": "clock outline",

  "structure.type.decimal.text": "Column",
  "structure.type.decimal.icon": "hashtag",

  "structure.type.tinyint.text": "Column",
  "structure.type.tinyint.icon": "hashtag",

  //Postgres
  "structure.type.text.text": "Column",
  "structure.type.text.icon": "font",

  "structure.type.timestamp without time zone.text": "Column",
  "structure.type.timestamp without time zone.icon": "clock outline",

  "structure.type.character varying.text": "Column",
  "structure.type.character varying.icon": "font",

  "structure.type.character.text": "Column",
  "structure.type.character.icon": "font",

  "structure.type.name.text": "Column",
  "structure.type.name.icon": "font",

  "structure.type.integer.text": "Column",
  "structure.type.integer.icon": "hashtag",

  "structure.type.double precision.text": "Column",
  "structure.type.double precision.icon": "hashtag",

  "structure.type.numeric.text": "Column",
  "structure.type.numeric.icon": "hashtag",

  "structure.type.jsonb.text": "Column",
  "structure.type.jsonb.icon": "file code outline",

  "structures.actions.editableDownload.tooltip":
    "Download editable structures aditional information",
  "structures.actions.upload.confirmation.header":
    "Upload additional information file of structures",
  "structures.actions.upload.tooltip":
    "Upload additional information file of structures",
  "quality.result_avg": "Current quality (%)",
  "quality.last_execution_at": "Last execution",

  //Business concepts relations
  "source.bc_father_of": "Father of",
  "target.bc_father_of": "Child of",
  "source.bc_related_to": "Related to",
  "target.bc_related_to": "Related to",
  "source.bc_translated_to_spanish": "Translated to spanish as",
  "target.bc_translated_to_spanish": "Translated to english as",
  "source.bc_parent": "Father of",
  "target.bc_parent": "Child of",

  //Implementations parameters
  "ruleImplementation.props.campo": "Field",
  "ruleImplementation.props.campo.placeholder":
    "Select the corresponding field",

  "ruleImplementation.props.campo_maestro": "Master field",
  "ruleImplementation.props.campo_maestro.placeholder":
    "Select the corresponding master field",

  "view.unauthorized.content":
    "If you want to update your permissions, please contact the administrator.",
  "view.unauthorized.head": "You are unauthorized",
  "appSetup.welcome.message": "Welcome to Truedat",
  "appSetup.configuration.message":
    "It seems that everything has been installed correctly. Let's begin configuring application so that we can start using it after a few simple steps",
  "appSetup.begin.button": "Begin",

  // Ingests
  "ingests.header": "Acuerdo de Interfaz",
  "alert.ingestAction.failed.header": "Error creating Interface Agreement",
  "alert.upload.failed.content":
    "Error loading files, no inserts have been made. Error in Interface Agreement [{row}], {error}",
  "alert.upload.success.content":
    "Loaded {count} Interface Agreement successfully",
  "filters.template": "Type",
  "ingest.error.existing.ingest":
    "({text}) There is already a Interface Agreement term with the indicated name",
  "ingest.events.add_resource_field":
    "linked a structure with the Interface Agreement",
  "ingest.events.create_ingest_draft":
    "created a new draft Interface Agreement",
  "ingest.events.delete_ingest_draft": "deleted a draft Interface Agreement",
  "ingest.events.delete_resource_field":
    "unlinked a structure from the Interface Agreement",
  "ingest.events.ingest_deprecated": "Interface Agreement archived",
  "ingest.events.ingest_published": "Interface Agreement published",
  "ingest.events.ingest_rejected": "Interface Agreement rejected",
  "ingest.events.ingest_rejection_canceled":
    "Interface Agreement rejection canceled",
  "ingest.events.ingest_sent_for_approval":
    "Interface Agreement sent for approval",
  "ingest.events.new_ingest_draft":
    "created a new draft form a published Interface Agreement",
  "ingest.events.update_ingest_draft": "Draft updated",
  "ingest.relatedTo.ingest": "Related to Interface Agreement",
  "ingestRelations.relatedDataIngest": "Related Interface Agreement",
  "ingests.actions.create": "New Interface Agreement",
  "ingests.actions.delete.confirmation.content":
    "The Interface Agreement will be deleted. Are you sure?",
  "ingests.actions.delete.confirmation.header": "Delete Interface Agreement",
  "ingests.actions.deprecate.confirmation.content":
    "The Interface Agreement will be deprecated. Are you sure?",
  "ingests.actions.deprecate.confirmation.header":
    "Deprecate Interface Agreement",
  "ingests.actions.upload.confirmation.header":
    "Upload Interface Agreement file",
  "ingests.crumbs.top": "Interface Agreement",
  "ingests.header": "Interface Agreement",
  "ingests.header.duplicate": "Duplicate Interface Agreement",
  "ingests.header.edit": "Edit Interface Agreement",
  "ingests.props.ingest_id": "Interface Agreement Id",
  "ingests.props.name": "Interface Agreement",
  "ingests.search.placeholder": "Search Interface Agreement...",
  "ingests.search.results.empty": "No Interface Agreement found",
  "ingests.subheader": "Query and manage Interface Agreement",
  "navigation.ingest": "Interface Agreement",
  "navigation.ingests": "Interface Agreement",
  "parentIngest.selector.label": "Parent Interface Agreement",
  "parentIngest.selector.placeholder": "Select an Interface Agreement...",
  "tabs.ie.ingest": "Interface Agreement",
  "tabs.ie.relationsIngests": "Related Interface Agreement",
  "tabs.se.ingests": "Interface Agreement",
  "ingest.relations.actions.data_structure.delete.confirmation.content":
    "Vinculation between Interface Agreement and structure will be deleted. Are you sure?",
  "filters.tipo_ingesta": "Agreement Type",
  "ingests.tabs.published": "Published",
  "ingest.events.relation_created": "Added relation",
  "ingest.events.relation_deleted": "Deleted Relation",

  //SIDEMENU
  "sidemenu.catalog_dashboard": "Catalog",
  "sidemenu.government_dashboard": "Governance",
  "sidemenu.basic_info_completeness_dashboard": "Basic info completeness",
  "sidemenu.datalakes_tables_evolution_dashboard": "Datalakes Tables Evolution",
  "sidemenu.tables_volumetry_dashboard": "Tables Volumetry",
  "templates.tabs.gr": "Grants",
  "sidemenu.ingests": "Interface Agreements",
  "sidemenu.relations": "Relations",

  //Relations Structures
  "source.Contiene": "Contains",
  "target.Contiene": "It's contained by",
  "source.Se calcula en base a": "Used to calculate",
  "target.Se calcula en base a": "Calculated by",
  "source.Se compone de": "Used to to compose",
  "target.Se compone de": "It's composed by",
  "source.Se construye con el informe": "It's built with the report",
  "target.Se construye con el informe": "Used to build",
  "source.Relacionado con": "Related to",
  "target.Relacionado con": "Related to",

  //Structures columns personalization
  "structure.alias": "Alias",

  "filtersGrid.field.modifier.cast_as_timestamp": "Cast as timestamp",
  "filtersGrid.field.modifier.cast_as_date": "Date format",
  "filtersGrid.field.modifier.to_string": "To string",
  "filtersGrid.field.modifier.substring": "Substring",
  "filtersGrid.field.modifier.substring.start": "String start position",
  "filtersGrid.field.modifier.substring.end": "String end position",
  "filtersGrid.field.modifier.concat": "Concatenate",
  "filtersGrid.field.modifier.concat.with_fields": "Fields"
};
