import React from "react";
import { useIntl } from "react-intl";

export default function SearchHomeAddon() {
  const { formatMessage } = useIntl();

  const iconClassNameGlossary = `icon ${formatMessage({
    id: "sidemenu.glossary.icon",
    defaultMessage: "tag"
  })} large`;
  const iconClassNameCatalog = `icon ${formatMessage({
    id: "sidemenu.catalog.icon",
    defaultMessage: "block layout"
  })} large`;
  const iconClassNameGrants = `icon ${formatMessage({
    id: "sidemenu.grants.icon",
    defaultMessage: "shield"
  })} large`;
  const iconClassNameRules = `icon ${formatMessage({
    id: "sidemenu.rules.icon",
    defaultMessage: "check square"
  })} large`;

  const blockStyles = {
    display: "block",
    background: "#ffffff",
    margin: "10px",
    padding: "20px",
    width: "calc(25% - 20px)",
    boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
    borderRadius: "8px",
    transition: "transform 0.3s ease",
    position: "relative",
    textAlign: "center",
    textDecoration: "none",
    color: "inherit",
    minWidth: "220px",
    maxWidth: "260px"
  };

  const iconStyles = {
    display: "inline-block",
    fontSize: "2.5em",
    color: "#037dba",
    margin: "10px 0 5px 0"
  };

  const h2Styles = {
    color: "#037dba",
    fontSize: "1.5em"
  };

  const pStyles = {
    color: "#666",
    fontSize: "0.9em"
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          padding: "20px"
        }}
      >
        <a href="/concepts" style={blockStyles}>
          <i className={iconClassNameGlossary} style={iconStyles}></i>
          <h2 style={h2Styles}>{formatMessage({ id: "search.glossary" })}</h2>
          <p style={pStyles}>{formatMessage({ id: "search.glossary_desc" })}</p>
        </a>

        <a href="/structures" style={blockStyles}>
          <i className={iconClassNameCatalog} style={iconStyles}></i>
          <h2 style={h2Styles}>
            {formatMessage({ id: "search.data_catalog" })}
          </h2>
          <p style={pStyles}>
            {formatMessage({ id: "search.data_catalog_desc" })}
          </p>
        </a>

        <a href="/structuresGrantRequests" style={blockStyles}>
          <i className={iconClassNameGrants} style={iconStyles}></i>
          <h2 style={h2Styles}>{formatMessage({ id: "search.grants" })}</h2>
          <p style={pStyles}>{formatMessage({ id: "search.grants_desc" })}</p>
        </a>

        <a href="/rules" style={blockStyles}>
          <i className={iconClassNameRules} style={iconStyles}></i>
          <h2 style={h2Styles}>
            {formatMessage({ id: "search.data_quality" })}
          </h2>
          <p style={pStyles}>
            {formatMessage({ id: "search.data_quality_desc" })}
          </p>
        </a>
      </div>
    </>
  );
}
